$media-mobile-max: 767px !default;
$media-desktop-min: 1025px !default;
$media-tablet-min: $media-mobile-max + 1 !default;
$media-tablet-max: $media-desktop-min - 1 !default;

$phone: "only screen and (max-width : #{$media-tablet-max})";
$tablet: "(min-width: #{$media-tablet-min})";
$desktop: "only screen and (min-width : #{$media-desktop-min})";

@mixin respond-to($media) {
  @if $media == phone {
    @media #{$phone} {
      @content;
    }
  } @else if $media == tablet {
    @media #{$tablet} {
      @content;
    }
  } @else if $media ==desktop {
    @media #{$desktop} {
      @content;
    }
  }
}

@include respond-to(phone) {
  .show-on-mobile {
    display: block;
  }
  .show-on-tablet,
  .show-on-desktop {
    display: none;
  }
}

@include respond-to(tablet) {
  .show-on-tablet {
    display: block;
  }
  .show-on-mobile,
  .show-on-desktop {
    display: none;
  }
}

@include respond-to(desktop) {
  .show-on-desktop {
    display: block;
  }
  .show-on-mobile,
  .show-on-tablet {
    display: none;
  }

  .hide-on-desktop {
    display: none;
  }
}

@media screen and (-webkit-min-device-pixel-ratio: 0) {
  select,
  textarea,
  input {
    font-size: 16px;
  }
}
