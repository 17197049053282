$color-input-error: #f00;

.form-field {
  margin-bottom: 10px;

  label { margin-bottom: 4px; display: block; }
  input, textarea, select, div[contenteditable='true'] {
    padding: 5px 30px 5px 8px;
    width: 100%;
    background-color: $color-white;
    border: 1px solid $color-gray80;
    color: $color-gray80;
    border-radius: 0;
    font-size: 15px;
    line-height: 17px;

    &.-error, &:invalid {
      background-color: lighten($color-error, 60%);
      border-color: lighten($color-error, 20%);
    }

    
    &:disabled {
      border-color: $color-gray50;
      color: $color-gray50;
    }
  }
  select {
    padding-top: 4px;
    padding-bottom: 4px;
    min-height: 29px;
  }

  input[type="text"],
  input[type="email"],
  input[type="password"],
  textarea {
    background-size: 20px;
    background-repeat: no-repeat;
    background-position: right 5px center;

    /*&:valid {
      background-image: url('/images/form-valid.png');
    }*/

    /*&:invalid:not(:placeholder-shown) {
      background-image: url('/images/form-invalid.png');
    }*/

    &.-full {
      width: 100%;
    }
  }

  textarea { background-position: right 5px top 5px; }

  .Select {
      .Select-input input {
      border: 0 none;
    }
    .Select-control {
      border-color: $color-gray80;
      border-radius: 0;
      height: 27px;
    }
    .Select-placeholder, .Select--single > .Select-control .Select-value {
      line-height: 27px;
    }
    .Select-input {
      height: 27px;
    }
  }

  .input-hint { margin: 8px 0; font-style: italic; font-size: 12px; }

  /* Variation */
  input[type="checkbox"] + label {
    position: relative;
    top: -2px;
  }
  &.-checkbox {
    label { display: inline-block; }
    input { margin: 0 3px 0 0; width: auto; }
  }
  &.-full {
    width: 100%;
  }
}

/* Complex Form Fields */

// Input List
/*.input-list {
    display: flex;
    flex-wrap: wrap;
}*/

// File Upload 
.file-upload {
  margin-bottom: 0;
  position: relative;
  
  .button { margin: 0; }
  input { 
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
  }
}

// Selected Item
.selected-item {
  margin: 0 8px 8px 0;
  padding: 3px 12px 3px 5px;
  background-color: lighten($color-primary, 65%);
  position: relative;
  border: 2px solid lighten($color-primary, 55%);
  border-radius: 4px;
  display: inline-block;

  .close {
    background: none;
    border: none;
    outline: none;
    text-align: center;
    position: absolute;
    font-size: 14px;
    line-height: 14px;
    height: 16px;
    top: -8px;
    right: -7px;
    padding: 1px;
    border-radius: 50%;
    background-color: lighten($color-primary, 55%);
    cursor: pointer;
    transition: background 200ms ease;

    &:hover {
      background-color: lighten($color-primary, 40%);
    }
    
    &:focus .svg-inline--fa {
      color: $color-error;
    }

    .svg-inline--fa {
      background-color: $color-white;
      font-size: inherit;
      color: $color-base;
      border-radius: 50%;
      line-height: inherit;
    }
  }
}

.form-feedback { margin-left: 15px; display: inline-block; opacity: 0; transition: all 500 ease-in;
  &.-success { opacity: 1; color: green; }
  &.-fail { opacity: 1;  color: red; }
}

/* Optional Wrappers */
.input-group {
  width: 100%;
  display: flex;
  flex-wrap: nowrap;

  &.-no-margin .form-field { margin: 0; }

  .form-field { 
    flex-grow: 1;
    &.-small {
      width: 120px;
      flex-grow: 0;
    }
    &.-x-small {
      width: 80px;
      flex-grow: 0;
    }
  }

  &:not(.-stacked) .form-field, &:not(.-stacked) .button, &:not(.-stacked) .validation-wrapper {
    margin-left: 10px;

    &:first-child {
      margin-left: 0;
    }
  }

  .button {
    margin: 0 0 0 10px;
    padding: 5px 8px 5px 8px;
    align-self: flex-end;
    border-radius: 0;
    line-height: 17px;
    -webkit-appearance: none;
    border: 1px solid transparent;

    &:last-child {
      margin-left: 0;
    }
  }

  &.-right:not(.-stacked) {
      justify-content: flex-end;

      .button, .form-field {
          margin-left: 10px;
          margin-right: 0px;

          &:first-child {
            margin-left: 0;
          }
      }
  }
  &.-stacked {
    flex-direction: column;
  }
  &.-dirty {
    .form-field {
      input {
        background-color: lighten($color-primary, 65%);
        border-color: $color-input-error;
      }
    }
  }
}

.validation-wrapper.-error {
  .form-field {
    input, textarea, select, div[contenteditable='true'] {
      background-color: lighten($color-error, 60%);
      border-color: lighten($color-error, 20%);
    }
  }
}

.react-datepicker-popper { z-index: 20; }

.measurement-input-group {
  display: flex;
  flex-direction: row;
  width: 100%;
  min-width: 150px;
  & > input, & > div {
    width: 50%;
  }
  .form-field { margin-bottom: 0; }
}


// Validation
.error-message {
  color: $color-error;
}
// https://codepen.io/dom111/pen/PmQeyr
.spelling-wrapper {
  margin-bottom: 8px;

  .form-field { margin-bottom: 3px; }
}
.spelling-message {
  color: $color-warning;
}
