// Typography variables
$font-family: "Open Sans", Arial, sans-serif;
$font-size: 13px;
$line-height: 18px;

$color-base: #333333;
$color-primary: #5caa7b;
$color-secondary: #f36f57;
$color-tertiary: #ffd152;
$color-detail: #bdbdbd;

$color-link: #428bca;
$color-valid: #124601;

/* Error Colors */
$color-error-border: #cc0000;
$color-error-bg: #f7dbe0;
$color-error: #8E2323;
$color-warning-border:  #f5b324;
$color-warning-bg: #f6e6c2;
$color-warning: #8a6d3b;
$color-info-border: $color-link;
$color-info-bg: #c9e5ef;
$color-info: #3294b6;
$color-success-border: #76bd22;
$color-success-bg: #dfeecc;
$color-success: #92c556;

$color-white: #ffffff;
$color-black: #000000;

/* 100% Shades of Grey */
$color-gray10: #efefef;
$color-gray20: #d6d6d4;
$color-gray40: #bdbdbd;
$color-gray50: #afafaf;
$color-gray60: #8c8d8e;
$color-gray80: #616265;
$color-gray90: #323232;
$color-gray95: #212121;

//Social Colors
$color-twitter: #5eb5e3;
$color-linkedIn: #3158a9;
$color-facebook: #0975a3;
$color-spiceworks: #fc5900;

//Media Queries
$max-width: 1500px;
$screen-lg-min: 1170px;
$screen-md-min: 970px;
$screen-sm-min: 750px;
$screen-xs-min: 580px;
$screen-xxs-min: 420px;

$screen-md-max: $screen-lg-min - 1;
$screen-sm-max: $screen-md-min - 1;
$screen-xs-max: $screen-sm-min - 1;
$screen-xxs-max: $screen-xs-min - 1;
