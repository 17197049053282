@import '../../node_modules/normalize.css';
$fa-font-path:   "/webfonts";
@import '../../node_modules/@fortawesome/fontawesome-free/scss/regular.scss';

@import 'base/variables';
@import 'base/mixins';
@import 'base/global';


/* Components */
@import 'components/buttons';
@import 'components/form-fields';
@import 'components/footer';
@import 'components/header';
@import 'components/info-buckets';
@import 'components/icon-circle';
@import 'components/icon-list';

/* Game */
@import 'pages/game';
@import 'components/gifts';

/* Landing Page */
@import 'pages/lp';