#footer {
  padding: 20px 0;
  color: $color-white;
  background-color: $color-gray95;

  h4 { color: $color-primary; }
  a { color: $color-white; text-decoration: none;
    &:hover { color: $color-gray20; }
  }

  .social { float: right; font-size: 22px; line-height: 1; }
}
