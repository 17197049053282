/* Buttons */
.button {
    margin: 10px 0;
    padding:15px 40px;
    border-radius: 3px;
    border: none;
	  font-size:15px;
    line-height: 15px;
	  font-weight: bold;
    text-decoration:none;
	  color: $color-white;
    cursor:pointer;
    white-space:nowrap;
    display: inline-block;

    i { font-size: .6rem; }

    &:active, &:link, &:visited {
       color: $color-white;
    }

    &:hover { text-decoration: none; }

    //Primary
    &.-primary { background-color: $color-primary;
        &:hover { background-color: lighten($color-primary, 10%); }

        &.-border { border: 1px solid $color-primary; color: $color-primary; background: #fff;
            &:hover { color: lighten($color-primary, 10%); border-color: lighten($color-primary, 10%); }
        }
    }

    &.-secondary { background-color: $color-secondary;
        &:hover { background-color: lighten($color-secondary, 10%); }

        &.-border { border: 1px solid $color-secondary; color: $color-secondary; background: #fff;
            &:hover { color: lighten($color-primary, 10%); border-color: lighten($color-primary, 10%); }
        }
    }

    //Default
    &.-default { background-color: $color-gray60;
        &:hover { background-color: lighten($color-gray60, 10%); }

        &.-border { border: 1px solid $color-gray60; color: $color-gray60; background: #fff;
            &:hover { color: lighten($color-gray60, 10%); border-color: lighten($color-gray60, 10%); }
        }
    }

    //Default Color
    @extend .-default;

    &.-disabled { opacity:.5; cursor:not-allowed; }



    &.-small { padding: 5px 10px; font-size: 13px; line-height: 13px; 

        .svg-inline--fa {
            font-size: 13px;
        }

        &.-icon {
            .svg-inline--fa {
                margin-left: 5px;
                margin-right: -5px;
            }
        }

        &.-left {
            .svg-inline--fa {
                margin-left: -2px;
                margin-right: 5px; 
            }
        }
    }
    &.-large { padding: 12px 65px; font-size: 15px; line-height: 15px; }
    &.-full { width: 100%; text-align: center; display: block; }
    &.-text { margin: 0; padding: 0; color: $color-link; background: none; text-align: left; white-space: normal; font-size: 13px; line-height: 13px; border-color: transparent; 
        div { margin-bottom: 5px; }
    }
    &.-default-color {
        color: $color-base;
    }
}

.button-group { 
    width: 100%;
    display: flex;
    align-items: center;

    h1, h2, h3, h4 {
        flex-grow: 1;
    }
    .image-stream {
        margin-right: 10px;
    }
    
    .button {
        margin-left: 0px;
        margin-right: 10px;

        &.-right { 
            margin-left: auto;
        }
    }

    /* Variations */
    &.-right {
        justify-content: flex-end;

        .button { 
            margin-left: 10px;
            margin-right: 0px;
        }
    }
    &.-center {
        justify-content: center;
    }

    &.-stacked {
        flex-direction: column;
    }
}
