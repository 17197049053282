/* Responsive Image */
@mixin img-responsive {
    max-width: 100%; height: auto;
  }
  
  /* Clearfix */
  @mixin clearfix {
      *zoom: 1;
      &:before, &:after {
          content: " ";
          display: table;
      }
      &:after {
          clear: both;
      }
  }
  
  /* No Select */
  @mixin no-select {
      -webkit-touch-callout: none;
      -webkit-user-select: none;
      -khtml-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
  }
  
  
  /* Fool-proof @font-face */
  /* Based on http://coding.smashingmagazine.com/2013/02/14/setting-weights-and-styles-at-font-face-declaration/ */
  
  @mixin font-face($font-family, $file-path, $font-weight, $font-style) {
    @font-face {
      font-family: $font-family;
        src: url('#{$file-path}.eot');
        src: url('#{$file-path}.eot?#iefix') format('embedded-opentype'),
             url('#{$file-path}.woff') format('woff'),
             url('#{$file-path}.ttf') format('truetype'),
             url('#{$file-path}.svg##{$font-family}') format('svg');
      font-weight: $font-weight;
      font-style: $font-style;
    }
    // Chrome for Windows rendering fix: http://www.adtrak.co.uk/blog/font-face-chrome-rendering/
    @media screen and (-webkit-min-device-pixel-ratio: 0) {
      @font-face {
        font-family: $font-family;
          src: url('#{$file-path}.svg##{$font-family}') format('svg');
      }
    }
  }
  