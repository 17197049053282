#header { @include clearfix;
  padding: 20px 0;
  color: $color-white;
  text-transform: uppercase;
  background: rgba($color-black, 0.5);
  width: 100%;
  position: fixed; top: 0; left: 0; z-index: 3;
  transition: all 300ms ease-in-out;
  overflow: hidden;
  /*&:before {
    display: block;
    width: 100%;
    height: 100%;
    background-image: url('/images/devices-mask.png');
    background-position: center 0;
    background-repeat: no-repeat;
    content: ' ';
    opacity: 0.8;
    position: absolute;
    top: 0; left: 0;
    filter: blur(5px);
  }*/

  &.-fixed { padding: 12px 0; background: rgba($color-black, 0.75); border-bottom-color: rgba(46,46,46,0.8);
    &:before { opacity: 0; }
  }

  .container { padding-top: 0; padding-bottom: 0; position: relative; z-index: 2; }

  a {
    margin: 0;
    padding: 0 10px;
    color: inherit;
    text-decoration: none;
    display: inline-block;
    border-right: 1px solid $color-white;
    &:last-child { border-right: none; }
    &:hover, &.-active { color: $color-white; }
  }

  .logo { width: 150px; }

  nav { float: right; font-size: 12px; }

}
