.icon-list { margin: 20px 0; padding: 0; list-style: none;
  font-size: 22px; color: $color-white; text-align: right;
  li { margin-right: 20px; display: inline-block; cursor: pointer;

    /* Variations */
    &.-active { color: $color-tertiary; }
  }


  &.-large { font-size: 32px; }
}
