@import "../../scss/responsive";
$red: #8b695b;
$green: #5caa7b;
$ice: #00beef;

.playlist {
  font-size: 12px;
  max-width: 900px;
  @include respond-to(tablet) {
    font-size: 16px;
  }
  width: 90%;
  display: flex;
  flex-direction: column;
  background: white;
  margin: 0 auto 25px;
  border: 3px solid $red;
  justify-content: flex-start;

  .scroll-wrapper {
    overflow: auto;
  }

  .gift-card {
    width: 100%;
    text-align: center;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-bottom: 3px solid $red;

    .gift-image-container {
      position: relative;
      width: 100%;
    }

    &:last-child {
      border-bottom: none;
    }

    .gift-image {
      max-width: 50%;
      @include respond-to(tablet) {
        max-height: 200px;
      }

      .gift-package {
        fill: $green;
      }
      .gift-package-ribbon {
        fill: darken($green, 10%);
      }
    }

    &.next-up {
      background: #666;
      max-height: 100px;
      h1 {
        color: white;
      }
    }

    &.current {
      background: rgba($ice, 0.3);
      color: #333333;
    }

    &.open {
      background: rgba($green, 0.3);
      .gift-image {
        max-height: 200px;

        .gift-package {
          fill: $red;
        }
        .gift-package-ribbon {
          fill: darken($red, 10%);
        }
      }
    }
    &.steal {
      background: rgba($red, 0.3);
    }
  }
  .steal-count-icon {
    position: absolute;
    font-size: 1.4em;
    text-decoration: none;
    z-index: 20;
    font-weight: bold;
    //left: calc(auto);
    margin-left: -20px;
    top: -10px;
    text-align: center;

    width: 50px;
    height: 50px;
    border-radius: 50px;
    color: #fff;
    line-height: 50px;
    background: $red;
  }
}
