@import "../../scss/responsive";
$red: #8b695b;
$green: #5caa7b;
$ice: #00beef;

.playerlist {
  font-size: 12px;
  max-width: 900px;
  @include respond-to(tablet) {
    font-size: 16px;
  }
  width: 90%;
  display: flex;
  flex-direction: column;
  background: white;
  margin: 0 auto;
  border: 3px solid $red;
  justify-content: flex-start;
  color: $red;
  padding: 2em;
  ul {
    list-style: none;
    li {
      line-height: 1.4em;
      font-size: 1.3em;
      padding: 0.2em 0;
    }
  }
}
