h2 {
    button {
        display:none;
    }
}

@media #{$phone} {
    .App {
        padding: 0;

        .app-title {
            padding-left: .2em;
        }

        .game-title {
            padding-right: .5em;
        }
    }

    .content {

        h2 {
            button {
                display:inline;
                min-height: 2em;
                color: #8b695b; ;
            }
        }
        .gifts {
            width: 100%;
            display: block;
            ul {
                margin-left: 0;

                li {
                    width: 45%;
                }
            }

            .details-gift  {
                padding: .2em .5em;
                img {
                    max-width: 85vw;
                }

                h3 {
                    font-size: 1.4em;
                }
            }
        }

        .players {
            display:none;
        }

        .turns {
            display:none;
        }
    }

    .content.player-view {
        .gifts {
            display:none;
        }

        .players {
            width: 100%;
            display: block;
        }

        .turns {
            display:none;
        }
    }

    .content.turns-view {
        .gifts {
            display:none;
        }

        .players {
            display:none;
        }

        .turns {
            width: 100%;
            display: block;
        }
    }

    

    
}