.info-buckets { margin: 20px 0 40px;
  .bucket { text-align: center;

  }
}


@media(min-width: $screen-xs-min) {
  .info-buckets { display: flex;
    .bucket { 
      width: 33%;
      padding: 0 20px; 
    }
  }
}
