#game { color: $color-white;
  #main {
    padding-top: 100px;
    background: url('/images/bg-burst.jpg') 0 0 no-repeat;
    background-size: cover;


    .game-input { margin: 0 20px; @include clearfix;
      a { color: inherit }
      .icon-list { margin-left: 20px; float: left; }
      .main-input { margin: 20px 0; min-width: 200px; overflow: hidden; position: relative; }
      .view-nav { float: right;
        .icon-list {
          li { margin-left: 20px; margin-right: 0; }
        }
      }
    }
  }
}
