.gifts {
    flex-grow: 2;
    text-align: left;
    background: rgb(255, 255, 255);
    box-shadow: 0 2px 2px 0 rgba(0,0,0,.14),0 3px 1px -2px rgba(0,0,0,.2),0 1px 5px 0 rgba(0,0,0,.12);
    
    .gift-well {
        position: relative;
    }

    .img-circle {
        border-radius: 50%;
        max-height: 60px;
        max-width: 40px;
        float: left;
        margin-right: 16px;
        border: 2px solid #5CAA7B;

    }

    ul {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: flex-start;
        margin-left: 2em;
        li {
            width: 30%;
            margin: .5em .5em;
        }
    }
    $gift-background-color: hsla(117, 68.9%, 27.5%, 0.354);
    .gift-item {
        padding-bottom: 1em;
        background:$gift-background-color;
        animation: gift-background-fade 1s backwards;
        -webkit-animation: gift-background-fade 1s backwards;
        -moz-animation: gift-background-fade 1s backwards;
    }

    @-webkit-keyframes gift-background-fade {
        50% {
            background:#F9DF9B;
        }
        100% {
            background: $gift-background-color;
        }
    }
    @-moz-keyframes gift-background-fade {
        50% {
            background:#F9DF9B;;
        }
        100% {
            background: $gift-background-color;
        }
    }
    @keyframes gift-background-fade {
        50% {
            background:#F9DF9B;;
        }
        100% {
            background:$gift-background-color;
        }
    }
    
    .gift-item.gone {
        background:rgba(153, 41, 41, 0.10);
        .mdl-list__item-avatar, .mdl-list__item-avatar.material-icons {
            background-color: #d3d3d3;
        }
    }

    .edit-gift {
        position: absolute;
        width: 100%;
        height: 100%;
        padding: 3em;
        background: white;
        box-sizing: border-box;
    }
    .details-gift {
        font-family: 'Noto Sans', sans-serif;
        width: 100%;
        height: 100%;
        padding: .2em 3em;
        background: white;
        box-sizing: border-box;

        h3 {
            margin-top: .2em;
        }

        .details-list {
            list-style: none;
            flex-direction: column;
            margin: 0;
            padding: 0;
        }

        img {
            max-height: 200px;
            border: 2px solid #5CAA7B;
        }
    }

}
