@import "../../scss/responsive";
$red: #8b695b;
$green: #5caa7b;
$ice: #00beef;

.giftlist {
  font-size: 12px;
  max-width: 900px;
  @include respond-to(tablet) {
    font-size: 16px;
  }
  width: 90%;
  min-height: 90vh;
  display: flex;
  flex-direction: column;
  background: white;
  margin: 0 auto;
  border: 3px solid $red;
  justify-content: flex-start;
  .scroll-wrapper {
    overflow: auto;
  }
  .giftitem {
    border: 1px solid $red;
    margin: 0 auto;
    text-align: center;
    .gift-image {
      max-width: 50%;
      @include respond-to(tablet) {
        max-height: 200px;
      }

      .gift-package {
        fill: $green;
      }
      .gift-package-ribbon {
        fill: darken($green, 10%);
      }
    }
  }
}
