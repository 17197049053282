html { box-sizing: border-box; }
*, *:before, *:after { box-sizing: inherit; }

body { font-size: 14px; line-height: 1.2; color: $color-base; font-family: $font-family; background-color: $color-gray95; }

a { color: $color-link; }
img { @include img-responsive; }

h1 {
    text-transform: capitalize;
}

h1, h2, h3, h4, h5, h6 {
    .memo {
        font-size: 80%;
        font-weight: normal;
    }

    transition: font-size 300ms;
    .svg-inline--fa {font-size: inherit;}
}

.txt-center { text-align: center; }
.txt-right { text-align: right; }
.txt-error { color: $color-error; }

/* Structural */
.container { margin: 0 auto; padding: 10px 20px; max-width: $max-width; @include clearfix; box-sizing: border-box; }
#root { background-color: $color-white; }


/* Global Variations */
.hide { display: none; }
.disable { 
    position: relative;
    &:before {
        content: ' ';
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%; 
        z-index: 2;
        background-color: $color-white;
        opacity: 0.5;
    }
}
.-shadow { box-shadow: 0px 0px 25px 0px rgba($color-black,0.75); position: relative; z-index: 2; }

fieldset { margin-bottom: 20px; }
.group {
    border: 1px solid $color-detail;
    margin-bottom: 10px;
    padding: 10px;
}

/* honeypot*/
#surname { display: none; }
