@import "responsive";
.watch {
  text-align: center;
  h1 {
    text-align: left;

    font-weight: 700;
    margin: 0;
    padding-top: 0;
    padding-bottom: 0.2em;
    font-size: 1.5em;
    font-family: "Nixie One", cursive;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    .app-title {
      margin-top: auto;
      color: #5caa7b;
    }
    .app-title-left {
      margin-right: 0.3em;
    }
    .app-title-right {
      margin-right: 0.3em;
      margin-left: 0.3em;
    }
    .game-title {
      margin-top: auto;
      margin-left: 0.3em;
      width: 100%;
      text-align: right;
      color: #8b695b;
    }
  }
  .content-wrapper {
    flex: 1;
    display: flex;
    justify-content: flex-start;
    width: 100%;
    overflow: hidden;
    min-height: 90vh;
  }
  .content {
    min-height: min-content; /* needs vendor prefixes */
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
  }
  .current-player {
    width: 100%;
  }
  h2 {
    background: hsla(24.4, 21.7%, 38.1%, 0.24);
    margin: 0;
    padding: 0.1em;
    text-align: center;
    font-size: 1.5em;
  }
  .players {
    width: 250px;
    text-align: left;
    background: rgb(255, 255, 255);
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
      0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);

    .players-container {
    }

    li {
      animation: player-background-fade 1s backwards;
      -webkit-animation: player-background-fade 1s backwards;
      -moz-animation: player-background-fade 1s backwards;
    }

    @-webkit-keyframes player-background-fade {
      50% {
        background: #f9df9b;
      }
      100% {
        background: #fff;
      }
    }
    @-moz-keyframes player-background-fade {
      50% {
        background: #f9df9b;
      }
      100% {
        background: #fff;
      }
    }
    @keyframes player-background-fade {
      50% {
        background: #f9df9b;
      }
      100% {
        background: #fff;
      }
    }
  }

  .turns {
    background: rgb(255, 255, 255);
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
      0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
    text-align: left;
    // li {
    //     animation: player-background-fade 1s backwards;
    //     -webkit-animation: player-background-fade 1s backwards;
    //     -moz-animation: player-background-fade 1s backwards;
    // }
  }
}

@import "watch-mobile";
