.blocks {
  .item {
    padding: 5px;
    .gift,
    .player {
      font-size: 15px;
      line-height: 25px;
      text-align: center;
      .edit {
        color: $color-white;
        cursor: pointer;
        background-color: rgba($color-black, 0.8);
        border-radius: 12px;
        width: 25px;
        height: 25px;
        position: absolute;
        top: 5px;
        right: 5px;
        z-index: 3;
        line-height: 25px;
      }
    }
    .gift {
      .steals {
        background-color: rgba($color-black, 0.8);
        border-radius: 12px;
        position: absolute;
        bottom: 5px;
        right: 5px;
        width: 25px;
        height: 25px;
        font-weight: bold;
        z-index: 4;
      }
    }

    &.-active,
    &.-inactive {
      .info-panel {
        .item-nav {
          .steals,
          .edit-gift {
            display: none;
          }
        }
      }
    }
  }

  /* List View */
  &.-list {
    color: $color-base;
    .item {
      padding: 2%;
      min-height: 50px;
      border-bottom: 1px solid $color-base;
      background: $color-white;
      position: relative;
      @include clearfix;
      .gift,
      .player {
        margin-right: 2%;
        float: left;
        border: 1px solid $color-detail;
        width: 120px;
        padding-bottom: 120px;
        position: relative;
        background-color: $color-white;
        background-repeat: no-repeat;
        background-size: cover;
        overflow: hidden;
        img {
          position: absolute;
          top: 0;
          left: 0;
        }
      }
      .player {
        display: none;
      }
      .gift {
        background-image: url("/images/unopened-gift.png");
      }

      .info-panel {
        @include clearfix;
        .copy {
          padding: 20px 0;
          float: left;
          strong {
            margin-bottom: 5px;
            display: block;
          }
          .person-name {
            font-weight: normal;
          }
        }
        .item-nav {
          font-size: 40px;
          line-height: 40px;
          float: right;
          a {
            padding-left: 20px;
            text-decoration: none;
            color: $color-base;
          }
        }
      }

      /* Variations */
      &.-active {
        background-color: rgba($color-primary, 0.8);
      }
      &.-inactive {
        opacity: 0.7;
      }
      &.-active,
      &.-inactive {
        .info-panel {
          .copy {
            .person-name {
              &:after {
                content: "";
              }
            }
          }
        }
      }
    }
  }

  /* Grid View */
  &.-grid {
    .item {
      margin-bottom: 20px;
      perspective: 1000px;

      .gift-name {
        display: none;
      }
      .person-name {
        display: block;
      }
      .info-panel {
        padding: 3%;
        background: rgba($color-black, 0.5);
        border: 2px solid rgba($color-black, 0.2);
        border-top: 0;
        .copy {
          margin-bottom: 8px;
          text-align: center;
          span {
            display: none;
          }
        }
        .item-nav {
          font-size: 30px;
          line-height: 30px;
          text-align: center;
          a {
            padding: 0 10px;
            color: $color-white;
            text-decoration: none;
          }
        }
      }
      .images {
        padding-bottom: 100%;
        position: relative;
        border: 2px solid rgba($color-gray60, 0.75);
        transform-style: preserve-3d;
        transition: 0.6s;
        .gift,
        .player {
          width: 100%;
          height: 100%;
          background-color: $color-white;
          background-position: center;
          background-size: cover;
          background-repeat: no-repeat;
          backface-visibility: hidden;

          position: absolute;
          top: 0;
          left: 0;
        }

        /* Player */
        .player {
          transform: rotateY(0deg);

          &:before {
            content: " ";
            display: block;
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            z-index: 2;
            visibility: hidden;
            background-color: rgba($color-black, 0.8);
            background-blend-mode: multiply;
          }
        }

        /* Gift */
        .gift {
          z-index: 2;
          /* for firefox 31 */
          transform: rotateY(180deg);

          background-image: url("/images/unopened-gift.png");
        }
      }

      /* Variations */
      &.flipped {
        .gift-name {
          display: block;
        }
        .person-name {
          display: none;
        }
        .images {
          transform: rotateY(180deg);
        }
      }
      &.-opened {
        .info-panel {
          .gift-name {
            display: block;
          }
          .person-name {
            display: none;
          }
        }
        .images {
          transform: rotateY(180deg);
        }
        &.flipped {
          .gift-name {
            display: none;
          }
          .person-name {
            display: block;
          }
          .images {
            transform: rotateY(0deg);
          }
        }
      }

      &.-inactive {
        border-color: rgba($color-gray40, 0.3);
        .player {
          &:before {
            visibility: visible;
          }
        }
      }

      &.-active {
        .images {
          border-color: rgba($color-secondary, 0.6);
        }
        .info-panel {
          background-color: rgba($color-secondary, 0.6);
        }
      }

      &.-no-steals {
        .gift {
          &:before {
            content: " ";
            display: block;
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            z-index: 4;
            background: url("/images/no-steals.png") center center no-repeat;
            background-size: 90% 90%;
            opacity: 0.35;
          }
        }
      }
    }
  }
}

@media (max-width: $screen-xxs-max) {
  .blocks {
    /* List View */
    &.-list {
      .item {
        .images {
          text-align: center;
        }
        .gift,
        .player {
          float: none;
          display: inline-block;
          img {
            position: absolute;
            top: 0;
            left: 0;
          }
        }
      }
    }
  }
}

//XX-Small
@media (min-width: $screen-xxs-min) and (max-width: $screen-xxs-max) {
  .blocks.-grid {
    display: flex;
    flex-wrap: wrap;
    .item {
      width: 50%;
    }
  }
}

//X-Small devices
@media (min-width: $screen-xs-min) and (max-width: $screen-xs-max) {
  .blocks.-grid {
    display: flex;
    flex-wrap: wrap;
    .item {
      width: 33.33%;
    }
  }
}

//Small devices
@media (min-width: $screen-sm-min) and (max-width: 1350px) {
  .blocks.-grid {
    display: flex;
    flex-wrap: wrap;
    .item {
      width: 20%;
    }
  }
}

//Medium devices
@media (min-width: 1351px) {
  .blocks.-grid {
    display: flex;
    flex-wrap: wrap;
    .item {
      width: 16.6666666667%;
    }
  }
}
