.enter-card {
    width: 100%;
    text-align: center;
    .mdl-card__title {
        justify-content: center;
    }
    .mdl-card__supporting-text {
        width: 100%;
    }
}

.mdl-list__item--two-line {
    height: auto;
}
.mdl-list__item--two-line .mdl-list__item-primary-content {
    height: auto;
}

.mdl-list__item-avatar, .mdl-list__item-avatar.material-icons {
    background-color: #5CAA7B;
}


@import "scss/watch";